import { browser } from '$app/environment';
import { PUBLIC_FIREBASE_API_KEY, PUBLIC_VAPID_DETAILS_PUBLIC_KEY } from '$env/static/public';
import { initializeApp } from 'firebase/app';
import type { BrowserPermissions } from 'fixee-server/lib/services/portal/portal.shared';
import type { NotificationDevice } from 'fixee-server/lib/services/user/user.schema';
import type { AddNotificationDeviceData } from 'fixee-server/lib/services/user/user.shared';
import toast from 'svelte-french-toast';
import type { Writable } from 'svelte/store';
import { UNIQUE_DEVICE_ID } from '../utils';
import useFixeeClient, { FIXEE_TOKEN_NAME } from './fixee-client';

export const initFirebase = () => {
	// We secured the key using : https://medium.com/@devesu/how-to-secure-your-firebase-project-even-when-your-api-key-is-publicly-available-a462a2a58843
	const firebaseConfig = {
		apiKey: PUBLIC_FIREBASE_API_KEY,
		authDomain: 'fixee-ubikio.firebaseapp.com',
		projectId: 'fixee-ubikio',
		storageBucket: 'fixee-ubikio.appspot.com',
		messagingSenderId: '428313869213',
		appId: '1:428313869213:web:861b950fd51c0080974d74',
		measurementId: 'G-C3F6SR8GY7'
	};

	// Initialize Firebase
	initializeApp(firebaseConfig);
};

function urlBase64ToUint8Array(base64String: string) {
	const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
	// eslint-disable-next-line no-useless-escape
	const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}

// Request permission for notifications
export function requestNotificationPermission(_: any, permissions?: Writable<BrowserPermissions>) {
	if (browser && 'Notification' in window) {
		const onNotificationPermissionChanged = (permission: NotificationPermission) => {
			if (permission === 'granted') {
				console.log('Notification permission granted.');
				navigator.serviceWorker.ready.then((registration) => {
					console.log('Service worker is ready');
					// Note: replace MY_PUBLIC_KEY with the VAPID public key
					const applicationServerKey = urlBase64ToUint8Array(
						PUBLIC_VAPID_DETAILS_PUBLIC_KEY
					);

					registration.pushManager
						.subscribe({
							userVisibleOnly: true,
							applicationServerKey: applicationServerKey
						})
						.then(async (subscription) => {
							console.log('Subscribed to notifications and service worker !');

							if (permissions)
								permissions.update((p) => ({ ...p, notifications: 'granted' }));

							const notificationDevice: NotificationDevice = {
								userAgent: navigator.userAgent,
								id: window.localStorage.getItem(UNIQUE_DEVICE_ID) || '',
								subscription:
									subscription.toJSON() as AddNotificationDeviceData['subscription']
							};

							// If there is a portalUserToken, we update the contact
							const params = new URL(window.location.href).searchParams;
							const portalUserToken = params.get('t');

							if (portalUserToken) {
								console.log(
									'requestNotificationPermission: Add new notification device to contact:',
									notificationDevice
								);
								await useFixeeClient().service('contact').addNotificationDevice({
									notificationDevice,
									portalUserToken
								});
							}

							// If the user is logged in, we update the user
							if (window.localStorage.getItem(FIXEE_TOKEN_NAME)) {
								console.log(
									'requestNotificationPermission: Add new notification device to user:',
									notificationDevice
								);
								await useFixeeClient()
									.service('user')
									.addNotificationDevice(notificationDevice);
							}
						})
						.catch((error) => {
							console.log('Subscription failed, error:', error);
						});
				});
			} else {
				if (permissions) permissions.update((p) => ({ ...p, notifications: 'denied' }));
				console.warn('Notification permission denied.');
				toast.error(_(`toasts.notificationsDenied`), { duration: 6000 });
			}
		};

		try {
			Notification.requestPermission().then(onNotificationPermissionChanged);
		} catch (error) {
			// Safari doesn't return a promise for requestPermissions and it
			// throws a TypeError. It takes a callback as the first argument
			// instead.
			if (error instanceof TypeError) {
				Notification.requestPermission(onNotificationPermissionChanged);
			} else {
				throw error;
			}
		}
	}
}

export function subscribeSwToPushNotifications() {
	if ('serviceWorker' in navigator) {
		// "scope" is important to make just the service worker is loaded from the root, without this there can be some issues
		navigator.serviceWorker
			.register(`/sw.js`, { scope: '/' })
			.then((registration) => {
				console.log('Notification service worker successfully registered');
			})
			.catch((error) => {
				console.log('Notification service worker registration failed, error:', error);
			});
	}
}
